

*{font-family: 'Montserrat', sans-serif;}

html{
    height: 100%;
}
body{ min-height:100%; padding:0; margin:0; position:relative; }

body::after {
    content: '';
    display: block;
    height: 100px; /* Set same as footer's height */
  }

.content a {
    font-weight:600;
    color: $dark-color;
}

.header{
    background-color: #EFEFEF;
    padding: 0px;
}

.header .header-top{
    padding: 16px;
    margin-left: 32px;
}

.header .header-top .header-catch-text{
    float: right;
    margin: 48px 32px;
}

.header .img-banner{
    width: 100%;
    object-fit: cover;
    max-height: 300px;
}

.content{
    max-width: $max-width;
    margin: auto;
    margin-top: 48px;
    min-height: 400px;
    padding: 0px 32px;
    padding-bottom: 48px;

    text-align: justify;
}

footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100px;
    background-color: #EFEFEF;
    display: table;
}

.footer .footer-element{
    padding: 0px 32px;
    height: 100px;
    display: table-cell;
    vertical-align: middle;
    width: 33.33%;
}

.fe-left{
    text-align: left;
}
.fe-center{
    text-align: center;
}
.fe-right{
    text-align: right;
}

.language-item {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
.footer-element .fa{
    margin: 0px 8px;
}



.content.h1,h2,a {
    color: $background-color;
    text-decoration: none;
}



.rounded-image{
    width: 200px;
    height: 200px;
    border-radius: 50%;
    object-fit: cover;
    margin: 20px 32px;
}


@media screen and (max-width: 600px) {
    .header .header-top{
        padding: 8px;
        margin-left: 16px;
    }

    .header .header-top .header-logo-image{
        width: 150px;
        object-fit: cover;
    }

    .header .img-banner{
        display: none;
    }

    .footer .fe-left{
        padding-right:8px;
    }
    .footer .fe-center{
        padding: 0px 8px;
    }
    .footer .fe-right{
        padding-left:8px;
    }

    .rounded-image{
        width: 100px;
        height: 100px;
        margin: 10px 12px;
    }
}


@media screen and (max-width: 450px) {
    .language-item .language-label{
        display: none;
    }
}

@media screen and (max-width: 800px) {
    .header .header-top .header-catch-text{
        display: none;
    }
}


@media (max-width: 600px) {
    .fa-icons-large {
      display:none;
    }
    .fa-icons-small {
      display:block;
    }
  }
  
  @media (min-width: 601px) {
    .fa-icons-large {
      display:block;
    }
    .fa-icons-small {
      display:none;
    }
  }