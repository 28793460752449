
.topnav {
    overflow: hidden;
    background-color: $background-color;
}
  
.topnav a {
    float: left;
    display: block;
    color: $nav-front-color;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
}

.topnav a:hover {
    background-color: $nav-front-color;
    color: $background-color;
}

.topnav a.active {
    background-color: $dark-color;
    color: $nav-front-color;
}

.topnav .icon {
    display: none;
}

@media screen and (max-width: 600px) {
    // .topnav a:not(:first-child) {display: none;}
    // .topnav a.icon {
    //     float: right;
    //     display: block;
    // }

    .topnav a:not(.active) {display: none;}
    .topnav a.active{
        display: inline-block;
        background-color: $background-color;
    }
    .topnav a.icon {
        float: right;
        display: block;
    }
}

@media screen and (max-width: 600px) {
    .topnav.responsive {position: relative;}
    .topnav.responsive .icon {
        position: absolute;
        right: 0;
        top: 0;
    }
    .topnav.responsive a {
        float: none;
        display: block;
        text-align: left;
    }
}